import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";

const Tagline = styled.div`
  width: 48rem;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
`;
const Heading = styled(Typography)`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Icon1 = styled.img`
  width: 1.625rem;
  position: relative;
  height: 1.625rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Text2 = styled.div`
  position: relative;
  line-height: 70%;
  font-weight: 600;
`;
const Text3 = styled(Typography)`
  &.MuiTypography-h2 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-87xl);
  line-height: 50%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: right;
  }
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const Divider = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--border-primary);
  box-sizing: border-box;
  height: 0.063rem;
`;
const Text4 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-15xl) 0rem 0rem;
  gap: var(--gap-base);
  text-align: right;
  font-size: var(--text-regular-light-size);
  font-family: var(--text-regular-light);
`;
const Stat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  background-image: url("/stat3@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 12.5rem;
`;
const Stat1 = styled.div`
  flex: 1;
  background-color: var(--color-sienna-100);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  min-width: 12.5rem;
`;
const Stat2 = styled.div`
  flex: 1;
  background-color: var(--color-silver);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  min-width: 12.5rem;
`;
const Stat3 = styled.div`
  flex: 1;
  background-color: var(--color-sienna-200);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  min-width: 12.5rem;
`;
const FrameIcon = styled.img`
  width: 1.625rem;
  position: relative;
  height: 1.625rem;
`;
const Stat4 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  background-image: url("/stat4@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 12.5rem;
`;
const Stats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  width: 25rem;
  position: relative;
  max-height: 100%;
  object-fit: cover;
`;
const Content2 = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-11xl);
  font-family: var(--heading-desktop-h4);
`;
const StatsRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-regular-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
`;

const StatsTwo = () => {
  return (
    <StatsRoot>
      <SectionTitle>
        <Tagline>Tagline</Tagline>
        <Content>
          <Heading variant='h2'>Mining Operations in the Philippines</Heading>
          <Text1>
            The Philippines has a rich history of mining and is currently
            positioned for a bright future in the industry. As of 31 July 2023
            there are 56 Metallic Mines operating in the Philippines.
          </Text1>
        </Content>
      </SectionTitle>
      <Content2>
        <Stats>
          <Stat>
            <Title>
              <Icon1 alt="" src="/icon.svg" />
              <Text2>Gold</Text2>
              <Text3 variant='h2'>12</Text3>
            </Title>
            <Content1>
              <Divider />
              <Text4>Working Mines</Text4>
            </Content1>
          </Stat>
          <Stat1>
            <Title>
              <Icon1 alt="" src="/frame2.svg" />
              <Text2>Copper</Text2>
              <Text3 variant='h2'>3</Text3>
            </Title>
            <Content1>
              <Divider />
              <Text4>Working Mines</Text4>
            </Content1>
          </Stat1>
          <Stat2>
            <Title>
              <Icon1 alt="" src="/frame3.svg" />
              <Text2>Chromite</Text2>
              <Text3 variant='h2'>4</Text3>
            </Title>
            <Content1>
              <Divider />
              <Text4>Working Mines</Text4>
            </Content1>
          </Stat2>
          <Stat3>
            <Title>
              <Icon1 alt="" src="/frame4.svg" />
              <Text2>Iron</Text2>
              <Text3 variant='h2'>4</Text3>
            </Title>
            <Content1>
              <Divider />
              <Text4>Working Mines</Text4>
            </Content1>
          </Stat3>
          <Stat4>
            <Title>
              <FrameIcon alt="" src="/frame5.svg" />
              <Text2>Nickel</Text2>
              <Text3 variant='h2'>33</Text3>
            </Title>
            <Content1>
              <Divider />
              <Text4>Working Mines</Text4>
            </Content1>
          </Stat4>
        </Stats>
        <PlaceholderImageIcon alt="" src="/placeholder-image2@2x.png" />
      </Content2>
    </StatsRoot>
  );
};

export default StatsTwo;
