import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";

const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Subheading = styled.a`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  text-decoration: none;
`;
const Imagewithtitle = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-7xs);
`;
const PlaceholderImageIcon1 = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 16.813rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Subheading1 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 300;
`;
const Imagewithtitle1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-7xs);
`;
const ImagewithtitleParent = styled.div`
  flex: 1;
  height: 38.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Heading = styled(Typography)`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const ItIsThe = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const AdditionallyThePhilippines = styled.p`
  margin: 0;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--heading-desktop-h3-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const LayoutRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: center;
  font-size: var(--text-small-normal-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const HistorySection = () => {
  return (
    <LayoutRoot>
      <Container>
        <ImagewithtitleParent>
          <Imagewithtitle>
            <PlaceholderImageIcon alt="" src="/placeholder-image3@2x.png" />
            <Subheading
              href={`https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.globaldata.com%2Fdata-insights%2Fmining%2Fthe-top-five-nickel-producing-countries-thousand-tonnes-2021%2F&psig=AOvVaw2-RWlaIcc-rb8l0hSs4FuG&ust=1692173164361000&source=images&cd=vfe&opi=89978449&ved=0CBEQjhxqFwoTCNDqjLea3oADFQAAAAAdAAAAABAJ`}
              target="_blank"
            >
              Top Five Nickel Producing Countries (Thousand Tonnes, 2021) -
              GlobalData
            </Subheading>
          </Imagewithtitle>
          <Imagewithtitle1>
            <PlaceholderImageIcon1 alt="" src="/placeholder-image4@2x.png" />
            <Subheading1>
              Philippines: copper production 2021 | Statista
            </Subheading1>
          </Imagewithtitle1>
        </ImagewithtitleParent>
        <Content>
          <Heading variant='h2'>
            The Philippines... a rich mining history and a promising future.
          </Heading>
          <Text1>
            <ItIsThe>{`It is the 2nd largest producer of nickel 3rd largest for underdeveloped gold projects and 4th for underdeveloped copper projects. The country is also known for being the 5th most mineralised country in the world. `}</ItIsThe>
            <ItIsThe>{`With the recent election of Ferdinand Marcos Jr as president mining has become a government priority. There are numerous domestic and international companies operating in the Philippines such as B2 Gold Goldfields OceanaGold Philex Mining RTG Mining Celsius and Apex Mining. `}</ItIsThe>
            <AdditionallyThePhilippines>
              Additionally the Philippines is part of ASEAN regional trade
              agreements including an investment chapter with trading partners
              like Australia.
            </AdditionallyThePhilippines>
          </Text1>
        </Content>
      </Container>
    </LayoutRoot>
  );
};

export default HistorySection;
