import * as React from "react";
import { useCallback } from "react";
import { Button } from "@mui/material";
import { navigate } from "gatsby";
import styled from "styled-components";

const Heading = styled.h2`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 33.75rem;
`;
const LearnMoreAbout = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const ContactUsFor = styled.p`
  margin: 0;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 300;
  color: var(--primary-main);
`;
const Button1 = styled(Button)`
  cursor: pointer;
`;
const Button2 = styled(Button)``;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-base);
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--text-medium-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const CtaRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: left;
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h3);
  background-color: var(--text-alternate);
`;

const DiscoverCTA = () => {
  const onButtonClick = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  return (
    <CtaRoot>
      <Container>
        <Heading>Discover the Mankayan Project Today</Heading>
        <Content>
          <Text1>
            <LearnMoreAbout>{`Learn more about one of the largest undeveloped copper-gold porphyry projects in the world. `}</LearnMoreAbout>
            <ContactUsFor>Contact us for further information.</ContactUsFor>
          </Text1>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="contained"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              onClick={onButtonClick}
            >
              Learn More
            </Button1>
            <Button2
              disableElevation={true}
              color="primary"
              variant="outlined"
              href='javascript:void(Tawk_API.toggle())'
            >
              Get in touch
            </Button2>
          </Actions>
        </Content>
      </Container>
    </CtaRoot>
  );
};

export default DiscoverCTA;
